<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Name') }}: {{ news.name }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('State') }}: {{ news.state }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Program') }}: {{ news.program }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Property') }}: {{ news.userProperty }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Segmentation') }}: {{ news.segmentation }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Position') }}: {{ news.position }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Description') }}: {{ news.description }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Notify By Mail') }}: {{ news.notifyByMail }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Comments') }}: {{ news.comments }}
                    </p>
                </article>
            </div>
        </div>
        <br>
        <div class="container columns is-centered is-half has-text-centered">
            <img :src="pages[0]"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Show',

    inject: ['i18n', 'http', 'route', 'routerErrorHandler', 'toastr'],

    data: () => ({
        news: {},
        newsImages: {},
        pages: [],
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http.get(this.route('news.show', this.$route.params.news))
                .then(({ data }) => {
                    this.news = data.news;
                    this.newsImages = data.news.news_images;
                    this.newsImages.forEach(newsImage => {
                        this.pages.push(this.route('newsImages.downloadImage', newsImage.id));
                    });
                }).catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';

.title-menu {
    font-size: 1.5rem;
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: $secondary;
}

.section-style{
    background: radial-gradient(circle, rgba(72,95,199,1) 35%, rgba(9,9,121,1) 100%);
    padding: 10px;
}

.titles{
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.titles1{
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.space{
    padding: 3px !important;
    &.btn {
        cursor: pointer;
    }
}

.butt{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

.textEmail{
    text-transform: none !important;
}

</style>
